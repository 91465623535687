@import url("https://fonts.cdnfonts.com/css/clash-display");
html[data-theme='dark'] body {
    --landing-background: #18181B;
    --landing-background-inverse: #FFFFFF;
    --landing-background-2: #27272A;
    --landing-background-3: rgba(29, 29, 32, 0.5);
    --landing-background-4: #FAFAFA;
    --text-color-1: #D4D4D8;
    --disabled-color: #7777AF;
    --disabled-color-2: #25254C;
    
    
}

html[data-theme='dark'] body .main-select-bg {
    background-image: url("../assets/images/dark-bg.png"); 
}

html[data-theme='light'] body {
    --landing-background: #FFFFFF;
    --landing-background-inverse: #18181B;
    --landing-background-2: #FAFAFA;
    --landing-background-3: #FAFAFA;
    --landing-background-4: rgba(29, 29, 32, 0.3);
    --text-color-1: var(--text-color);
    --disabled-color-2: #25254C;
    
}

html[data-theme='light'] body .main-select-bg {
    background-image: url("../assets/images/light-bg.png"); 
}

html[data-theme='dark'] body, html[data-theme='light'] body{
    font-family: "HelveticaNeue","Segoe UI",sans-serif;
}

html h1,html h2, html h3{
    font-family: 'Clash Display',"Segoe UI",sans-serif;
}

body{
    --success-color: #22C55E;
    
}
.ant-picker-cell-disabled .ant-picker-cell-inner{
    color: var(--border)!important;
    cursor: not-allowed!important;
}

