body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-default {
  padding-bottom: 8px !important;
}

.ant-form-item-label label {
  width: 100% !important;
}
.ant-form-item-label {
  padding: 2px !important;
}

.hint {
  
  width: 100%;
  text-align: left;
}
.hint span {
  color: rgb(129, 136, 163) !important;
}