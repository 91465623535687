
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;1,300&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, Jost, Raleway, sans-serif, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}



@font-face {
  font-family: "HelveticaNeueMed";
  src: url("./fonts/helveticaneue/HelveticaNeue Light.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "HelveticaNeue";
  src: url("../src/fonts/helveticaneue/HelveticaNeue.ttf") format("truetype");
  font-weight: bold;
} */

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/helveticaneue/HelveticaNeue Thin.ttf") format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/helveticaneue/HelveticaNeue Light.ttf") format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/helveticaneue/HelveticaNeue Medium.ttf") format('truetype');
}